<template>
  <div class="page">
    <Navbar :title="'商城'" />
    <div class="header">
      <div class="user">
        <img class="user_avater" :src="user.avater" />
        <div class="user_box">
          <div class="user_box_name">{{ user.name }}</div>
          <div class="user_box_text">
            我的积分: <span>{{ user.val }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="border"></div>
    <div class="main">
      <div class="main_tab">
        <div
          @click="tabCheck(index)"
          class="main_tab_item"
          :class="{ main_tab_item_on: item.check }"
          v-for="(item, index) in listTab"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="shop_item_bg" v-for="(item, index) in list" :key="index">
        <div class="shop_item">
          <img :src="item.img" alt="" />
          <div class="shop_name">{{ item.name }}</div>
          <div class="shop_integral">{{ item.integral }} 积分</div>
          <div class="shop_num">剩余：{{ item.val }}/{{ item.total }}</div>
        </div>
      </div>
      <NoData v-if="list.length > 0 && listNoMore" title="暂无更多商品~" />
      <NoData v-if="list.length == 0" title="暂无商品~" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {
        avater: require("@/assets/img/avater/1.png"),
        name: "小小",
        val: 0,
      },
      listTab: [
        { name: "全部", check: true },
        { name: "实物", check: false },
        { name: "优惠券", check: false },
        { name: "优惠券", check: false },
        { name: "优惠券", check: false },
        { name: "金币", check: false },
      ],
      list: [
        {
          name: "Áo thun Hoàng Hậu Cát Tường",
          integral: "13000",
          val: 0,
          total: 50,
          img: "https://img3.muugame.com/images/prop-1667957947.png",
        },
        {
          name: "Áo thun Hoàng HậuCát Tường",
          integral: "13000",
          val: 20,
          total: 50,
          img: "https://img3.muugame.com/images/prop-1667957947.png",
        },
        {
          name: "Áo thun Hoàng ",
          integral: "15000",
          val: 3,
          total: 50,
          img: "https://img3.muugame.com/images/prop-1667957947.png",
        },
        {
          name: "Áo thun Hoàng Hậu",
          integral: "33000",
          val: 1,
          total: 50,
          img: "https://img3.muugame.com/images/prop-1667957947.png",
        },
      ],
      listNoMore: true,
    };
  },
  methods: {
    tabCheck(key) {
      this.listTab.forEach((item) => (item.check = false));
      this.listTab[key].check = true;
    },
    goUserInfo() {
      this.$router.push("/my/userInfo");
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  padding: 0.4rem 0.3rem 0.4rem 0.2rem;
  width: 100%;
  box-sizing: border-box;
}
.border {
  width: 100%;
  height: 0.14rem;
  background-color: #f0f0f0;
}
.user {
  display: flex;
  align-items: center;
  width: 100%;
}
.user_avater {
  margin-right: 0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  border: 1px solid $color-primary;
}
.user_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 0.7rem;
  line-height: 1;
}
.user_box_name {
  font-size: 0.28rem;
  font-weight: bold;
}
.user_box_text {
  font-size: 0.24rem;
  color: #999;
}
.user_box_text span {
  color: $color-primary;
  font-weight: bold;
}
.main {
  position: relative;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 1rem 0.2rem 0.1rem 0.2rem;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}
.main_tab {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.2rem;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  box-sizing: border-box;
}
.main_tab::-webkit-scrollbar {
  -webkit-appearance: none;
}
.main_tab::-webkit-scrollbar:vertical {
  width: 1.4rem;
}
.main_tab::-webkit-scrollbar:horizontal {
  height: 0.06rem;
}
.main_tab_item {
  display: inline-block;
  margin-right: 0.3rem;
  padding: 0.08rem 0.24rem;
  background-color: #e1e1e1;
  border: 1px solid transparent;
  color: #8d8d8d;
  font-size: 0.23rem;
  border-radius: 1rem;
}
.main_tab_item_on {
  background-color: transparent;
  border: 1px solid $color-primary;
  color: $color-primary;
}
.shop_item_bg {
  padding: 0.1rem;
  width: 50%;
  box-sizing: border-box;
}
.shop_item {
  padding: 0.18rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 5px #ddd;
  font-size: 0.2rem;
  color: #8d8d8d;
}
.shop_name {
  margin-top: 0.1rem;
  width: 100%;
  color: $color-primary;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.shop_integral {
  margin: 0.05rem 0;
}
</style>